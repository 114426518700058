<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="Salesitems"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-pagination
                disable-sort
                :height="Salesitems.length < 2 ? '150px' : null"
                hide-default-header
                hide-default-footer
                item-key="index"
                single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-5 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-5 pa-1 red--text">{{ headers[1].text }}</th>
                    <th class="grey lighten-5 pa-1 red--text text-right width-6-pct"><div class="mr-5">{{ headers[2].text }}</div></th>
                    <th class="grey lighten-5 pa-1 red--text text-no-wrap text-right width-6-pct"><div class="mr-5">{{ headers[3].text }}</div></th>
                    <th class="grey lighten-5 pa-1 text-right text-no-wrap width-6-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-5 pa-1 text-no-wrap text-center width-5-pct">{{ headers[5].text }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{item}">
                <div class="mb-3 ml-1">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.description="{item}">
                <v-text-field
                        :value="item.description"
                        :placeholder="$t('message.enterAnyDescription')"
                        class="custom pa-1 force-left"
                        clearable
                        dense
                        v-if="item.type == 'default'"
                ></v-text-field>
            </template>
            <template v-slot:item.quantity="{item}">
                <v-text-field
                        :value="item.quantity ? item.quantity : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'quantity',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.unitprice="{item}">
                <v-text-field
                        :value="item.unitprice ? item.unitprice : 0"
                        class="custom pa-1"
                        dense
                        @input="updateSalesItemRowHandle( item.index, 'unitprice',$event)"
                        type="number"
                        v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.invoiceamount="{item}">
                <div class="text-right mb-3" v-if="item.type == 'default'">{{ formatThisNumber(item.invoiceamount,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="text-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index)" class="mb-3" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-5 pt-5 px-0 d-flex justify-start">
                        <v-btn
                                @click="addManualInvoiceItemRow"
                                color="success"
                                small
                        >{{ Salesitems.length == 0 ? $t('message.addItem') : $t('message.addMore') }}
                        </v-btn>
                        <v-btn
                                @click="resetManualInvoiceItems"
                                class="ml-3"
                                color="default"
                                :loading="resettingManualInvoiceitems"
                                small
                        ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-7 pa-0">
                        <v-row justify="end">
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black red--text">{{ $t('message.invoiceCurrency') + '*' }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="7" offset="5">
                                            <v-select
                                                    :items="currencies"
                                                    class="custom py-0 mt-0"
                                                    hide-details
                                                    item-text="Currency.code"
                                                    item-value="Currency.id"
                                                    reverse
                                                    style=""
                                                    v-model="Invoice__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalAmount') + '(' + currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</div>
                                <div class="text-right font-md font-weight-black">{{ formatThisNumber(itemsTotalAmount,currencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {getSafe, numberFormat} from "../../helpers/helpers";
    import { debounce } from "debounce";
    import ForexView from "./ForexView";
    import { api } from "../../api";

    export default {
        name: "ManualInvoiceItemList",
        components: {ForexView},
        data() {
            return {
                currentRowIndex: 0,
                resettingManualInvoiceitems: false,
            }
        },
        computed: {
            ...mapFields('invoice', {
                Invoice__id: 'current.Invoice.id',
                Invoice__currency_id: 'current.Invoice.currency_id',
                Invoice__currencyrate: 'current.Invoice.currencyrate',
                openForexView: 'openForexView'
            }),
            ...mapMultiRowFields('manualinvoiceitem',{
                Salesitems: 'current.Salesitems'
            }),
            ...mapGetters('manualinvoiceitem',{
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalQty: 'itemsTotalQty',
            }),
            ...mapGetters([
                'appicUrls',
                'currencies',
                'uofmQty'
            ]),
            headers() {
                let headers = [
                    {text: this.$t('message.lineNo'), value: 'lineNumber'},
                    {text: this.$t('message.description'), value: 'description'},
                    {text: this.$t('message.quantity'), value: 'quantity'},
                    {text: this.$t('message.price'), value: 'unitprice'},
                    {text: this.$t('message.amount'), value: 'invoiceamount'},
                    {text: this.$t('message.actions'), value: 'index'},
                ]
                return headers
            }
        },
        methods: {
            ...mapActions('manualinvoiceitem',{
                resetSalesItems: 'resetSalesItems',
                updateSalesItems: 'updateSalesItems',
            }),
            ...mapMutations('manualinvoiceitem',{
                addManualInvoiceItemRow: 'addManualInvoiceItemRow',
                deleteManualInvoiceItemRow: 'deleteManualInvoiceItemRow',
                updateTotals: 'updateTotals'
            }),
            async deleteItem(itemIndex) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        this.deleteManualInvoiceItemRow(itemIndex)
                        resolve('ok')
                    });
                    let _this = this
                    promise.then(await _this.updateTotals)
                }
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            resetManualInvoiceItems() {
                this.resettingManualInvoiceitems = true
                this.resetSalesItems().then(()=> {
                    setTimeout(() => {
                        this.Salesitems.forEach((item, index) => {

                        })
                        this.resettingManualInvoiceitems = false
                        this.$toast.success(this.$t('message.invoiceItemsResetNotification'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    }, 2000)
                })
            },
            updateSalesItemRowHandle(idx,field,value) {
                let payload = {
                    index: idx,
                    field: field,
                    value: value,
                }
                let promise = new Promise((resolve, reject) => {
                    this.$store.commit('salesitem/updateSalesItems', payload)
                    resolve('ok')
                });
                let _this = this
                promise.then(_this.updateTotals)
            },
        },
        watch: {

        },
        mounted(){
            var _this = this
            this.resetSalesItems()
            console.log(this.Salesitems)
        }
    }
</script>

<style>
    .force-left .v-text-field__slot input {
        text-align: left;
    }
    .custom.v-text-field {
        font-size: 0.75rem !important;
    }
    .custom.v-select {
        font-size: 0.75rem !important;
    }
</style>